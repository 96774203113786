import request from '@/utils/request';

export const getUserCouponList = (data) =>
  request.post('/web/coupon/getUserCouponList', data); // 优惠券列表

export const smartCabinetReopen = (data) =>
  request.post('/web/vehicle/smartCabinetReopen', data);

export const checkReopenDoorRight = (data) =>
  request.post('/web/order/checkReopenDoorRight', data); // 是否有重开柜门的权限

export const getOpenDoorStatusByOrderCode = (data) =>
  request.post('/web/order/getOpenDoorStatusByOrderCode', data); // 查询柜门是否打开

export const orderDetail = (data) =>
  request.post('/web/order/orderDetail', data); // 订单接口

// 鸣笛
export const playWhistle = (data) => {
  return request.post(
    `/vehicle-center/sound/playWhistle?vin=${data.vin}`,
    data
  );
};
// 声音列表
export const loadSounds = (data) =>
  request.post('/vehicle-center/sound/loadSounds', data);

// 播放声音
export const playSound = (data) => {
  return request.post(
    `/vehicle-center/sound/playSound?vin=${data.vin}&id=${data.id}&playCount=${data.playCount}`,
    data
  );
};
// 暂停播放
export const pauseSound = (data) =>
  request.post(`/vehicle-center/sound/pauseSound?vin=${data.vin}`, data);

// 喇叭音量控制
export const speakerVolumeControl = (data) => {
  return request.post(
    `/vehicle-center/sound/speakerVolumeControl?vin=${data.vin}&volume=${data.volume}`,
    data
  );
};
export const buriedPoint = (data) => request.post('/buriedPoint/point ', data); // 埋点

export const getPromotionInfo = (data) =>
  request.post('/coupon/getPromotionInfo', data); //获取活动信息

export const graspCoupon = (data) => request.post('/coupon/graspCoupon', data); // 领取活动券

export const getVerificationCode = (data) =>
  request.post('/app-center/h5/getVerificationCode', data); // 获取验证码

export const verifyCode = (data) =>
  request.post('/app-center/h5/verifyCode', data); // 获取验证码

export const deliveryPageList = (data) =>
  request.post('/aqua-center/app/order/merchant/delivery/pageList', data); //我的订单列表

export const deliveryDetail = (data) =>
  request.post('/aqua-center/outside/h5/delivery/detail', data); //订单详情APPH5

export const cancelOrder = (data) =>
  request.post('/aqua-center/aquaOrderApi/delivery/cancelOrder', data); // 取消订单

export const openDoor = (data) =>
  request.post('/vehicle-center/vehicle/delivery/openDoor', data); // 开门投柜

export const confirmForArk = (data) =>
  request.post('/aqua-center/aquaOrderApi/delivery/confirmForArk', data); // 确认投柜更新运单状态

export const vinRunStatusInfo = (data) =>
  request.post('/vehicle-center/vehicle/getRunStatusInfoWithoutLogin', data); //订单详情APPH5车辆信息

export const getParks = () => request.get('/vehicle-center/park/getParks'); // 获取下拉网格

export const getStationListByParkCode = (data) =>
  request.post('/vehicle-center/station/getStationListByParkCode', data); // 获取站点

export const deleteById = (data) =>
  request.post('/vehicle-center/station/deleteById', data); // 根据id删除站点

export const findGridPointInfo = (data) =>
  request.post(
    '/vehicle-center/mapPoint/findGridPointAndCoordinateByCode',
    data
  ); // 获取网格详情

export const getStationListByCoordinate = (data) =>
  request.post('/vehicle-center/station/getStationListByCoordinate', data); // 根据经纬度查询附近站点

export const insertAutoPilotAStation = (data) =>
  request.post('/vehicle-center/station/insertAutoPilotAStation', data); // 插入站点

export const queryMission = (data) =>
  request.post('/vehicle-center/vehicleMissionStatistic/queryMission', data); // 数据中心智能任务查询

export const queryDriver = (data) =>
  request.post('/vehicle-center/vehicleMissionStatistic/queryDriver', data);

export const secondDelivery = (data) =>
  request.post('/order-center/expressOrder/secondDelivery', data); // 二次配送

export const offlineDelivery = (data) =>
  request.post('/order-center/expressOrder/offlineDelivery', data); // 线下配送

export const selectPageList = (data) =>
  request.post('/order-center/expressOrder/selectPageList', data); // 查询列表接口

export const moveMissionTypeList = (data) =>
  request.post('/vehicle-center/vehicleDispatch/moveMissionTypeList', data); // 停车微调列表

export const moveMissionDispatch = (data) =>
  request.post('/vehicle-center/vehicleDispatch/moveMissionDispatch', data); // 停车微调发送接口

export const queryAllDriver = () =>
  request.post('/dashboard-center/vehicleMissionStatistic/queryAllDriver'); // 获取下拉网格
export const getRoleProductInfo = (data) =>
  request.post('/user-center/user/roleProductInfo', data); // 获取准时达菜单权限接口
//  1.8.0
export const getRecentMissionDayListByVin = (data) =>
  request.post(
    '/vehicle-center/vehicleMission/getRecentMissionDayListByVin',
    data
  ); // 获取车辆任务记录
export const justOpenDoor = (data) =>
  request.post('/order-center/expressOrder/offlineDelivery', data); // 仅开门，不退库和退库

export const getAbnList = (data) =>
  request.post('/order-center/xb/abnList', data); // 退库原因列表
export const getInstalledOrderList = (data) =>
  request.post('/order-center/expressOrder/selectPageList', data); // 已投柜列表查询
export const cancelDeliveryRequest = (data) =>
  request.post('/order-center/expressOrder/cancelDelivery', data); // 取消配送
export const deleteOrderRequest = (data) =>
  request.post('/order-center/expressOrder/deleteById', data); // 删除订单

export const getMissionTimeLineDetail = (data) =>
  request.post('/vehicle-center/vehicleMission/getMissionTimeLineDetail', data); // 获取任务详情

export const getMissionRouteTrace = (data) =>
  request.post('/vehicle-center/vehicleMission/getMissionRouteTrace', data); // 获取任务轨迹

export const getSpeedTrend = (data) =>
  request.post('/vehicle-center/vehicleMission/getSpeedTrend', data); // 获取任务速度

export const queryProAndCityWithCenter = (data) =>
  request.post('/vehicle-center/park/queryProAndCityWithCenter', data); // 数据中心 -- 省、市、网络数据接口
export const queryDataCenterList = (data) =>
  request.post('/dashboard-center/dataCenter/queryDataCenterList', data); // 数据中心 -- 图表接口
export const querOverviewList = (data) =>
  request.post('/order-center/statistics/queryOverviewList', data); // 数据中心 -- 核心总览数据

export const getVinInfoForVedio = (data) =>
  request.post('/vehicle-center/vehicle/getVinInfoForVedio', data); // 查询灯光

export const lampControl = (data) =>
  request.post('/vehicle-center/vehicle/lampControl', data); // 修改灯光

export const getVinDoorNums = (data) =>
  request.post('/vehicle-center/vehicle/getVinDoorNums', data); //  C格口柜门数量查询

export const vehicleOpenDoor = (data) =>
  request.post('/vehicle-center/vehicle/openDoor', data); //  开柜门

export const orderDetailSendVin = (data) =>
  request.post('/aqua-center/aquaOrderApi/delivery/orderDetailSendVin', data); //  开柜门

export const adControl = (data) =>
  request.post('/vehicle-center/vehicle/adControl', data); //  开柜门

export const vehicleTaskDetails = (data) =>
  request.post(`/vehicle-center/sf/h5/vehicleTaskDetails/${data}`, {}); //  顺丰订单详情

export const getParksByQuery = (data) =>
  request.post('/vehicle-center/park/getParkList', data); // 根据名称获取网格集合

export const addSearchHistory = (data) =>
  request.post('/vehicle-center/park/addSearchHistory', data); // 站点搜索网点-插入网格搜索历史

export const deleteHistoryById = (id) =>
  request.get('/vehicle-center/park/deleteById?id=' + id); // 网格搜索历史-删除记录接口

export const querySearchHistory = (data) =>
  request.post('/vehicle-center/park/querySearchHistory', data); // 站点管理-网格搜索历史-查询列表接口

export const sideScreenControl = (data) =>
  request.post('/vehicle-center/sideScreen/screenControl', data); // 侧屏开关控制

export const sideVolumeControl = (data) =>
  request.post('/vehicle-center/sideScreen/volumeControl', data); // 侧屏音量控制

export const getVehicleMissionSubway = (vin) =>
  request.get('/vehicle-center/vehicleMission/getVehicleMissionSubway?vin=' + vin); // 查看路线接口
export const securityVehicleStatus = (vin) =>
  request.get('/aqua-center/securityVehicle/status?vin=' + vin); // 安防警灯侧屏控制
