<template>
  <div ref="container" class="missionBarContainer">
    <div
      :style="{ width: `${(list.length - 1) * itemWidth}px` }"
      class="missionBar"
    ></div>
    <ul
      class="missionList"
      :style="{ width: `${(list.length - 1) * itemWidth + 100}px` }"
    >
      <li v-for="(item, index) in list" :key="index" class="listItem">
        <div>
          <img
            v-if="index === 0"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_start.png"
            alt=""
          />
          <img
            v-else-if="index === list.length - 1 && [3, 4, 6].includes(status)"
            :src="`https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_status_${status}.png`"
            alt=""
          />
          <img
            v-else
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_normal.png"
            alt=""
          />
        </div>
        <div>{{ item.content }}</div>
        <div v-if="item.endTime">{{ item.startTime }}~{{ item.endTime }}</div>
        <div v-else>{{ item.startTime }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['list', 'status'],
  data() {
    return {
      itemWidth: 118,
      startX: null,
      startY: null,
    };
  },
  methods: {
    bindScroll(elem) {
      elem.addEventListener('touchstart', (event) => {
        this.startX = event.targetTouches[0].pageX;
        this.startY = event.targetTouches[0].pageY;
        elem.addEventListener('touchmove', this.touchMove, { passive: false });
      });
      elem.addEventListener('touchend', () => {
        elem.removeEventListener('touchmove', this.touchMove);
      });
    },

    touchMove(event) {
      event?.preventDefault();
      const pageX = event.targetTouches[0].pageX;
      const pageY = event.targetTouches[0].pageY;
      if (Math.abs(pageY - this.startY) > 10) {
        this.$refs.container.removeEventListener('touchmove', this.touchMove);
      }
      const offset = pageX - this.startX;
      this.startX = pageX;
      this.$refs.container.scrollLeft -= offset;
    },
  },
  mounted() {
    const container = this.$refs.container;
    this.bindScroll(container);
  },
};
</script>

<style lang="scss" scoped>
$itemWidth: 118px;
.missionBarContainer {
  font-size: initial;
  width: 100vw;
  overflow: auto;
  margin: 20px 0;

  &::-webkit-scrollbar {
    display: none;
  }
  .missionBar {
    transform: translate(50px, 16px);
    height: 8px;
    background-image: url(https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/zsd_190/timeBar_bg.png);
    background-size: 24px 4px;
    background-repeat: repeat-x;
  }
  .missionList {
    width: 100%;
    position: relative;
    display: flex;
    .listItem {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: $itemWidth;
      div {
        &:nth-child(1) {
          background: #f6f7f8;
          img {
            width: 18px;
            height: 18px;
          }
        }
        &:nth-child(2) {
          font-size: 12px;
          color: #000000;
          letter-spacing: -0.7px;
          font-weight: 400;
          margin-top: 4px;
          margin-bottom: 2px;
          width: max-content;
        }
        &:nth-child(3) {
          font-size: 12px;
          color: #9798ab;
          letter-spacing: -0.61px;
          font-weight: 400;
          width: max-content;
        }
      }
    }
  }
}
</style>
