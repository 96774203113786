<template>
  <div ref="headerRef" class="header">
    <div
      class="space"
      :style="{ height: `calc(${appBarHeight + 40}px)` }"
    ></div>
    <div :style="{ paddingTop: appBarHeight + 'px' }" class="navBar">
      <img
        @click="$emit('onBack')"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div>{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      appBarHeight: parseInt(sessionStorage.getItem('_appBarHeight') ?? 0),
    };
  },
  methods: {
    // handleBack() {
    //   this.$emit("onBack");
    // },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  background: #f6f7f8;
  .space {
    height: 0.8rem;
    background: white;
  }
  .navBar {
    position: fixed;
    left: 0;
    z-index: 9;
    top: 0;
    width: 100vw;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
    background: white;
    line-height: 0.8rem;
    font-size: 0.3rem;

    img {
      width: 0.64rem;
      height: 0.64rem;
      position: absolute;
      bottom: 0.08rem;
      left: 0.2rem;
    }
    div {
      background: white;
    }
  }
}
</style>
